import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Row, Col } from '../components/global'
import PageCard from '../components/home/pageCard'

const ThankYouPage = () => (
  <Layout>
    <SEO title="Thank You For Your Order" />
    <div className="container container-last">
      <h1>Thank You For Your Order!</h1>
      <p>
        Shipping and handling charges are added to your order and are shown on your invoice or sales
        receipt sent to you by email. We ship your order by the most economical means.
      </p>
      <p>
        Your order is processed in 2-3 business days. Make sure your complete street mailing address
        and phone number is on the shopping cart order form. This is very important for
        international orders. Email us right away with any address corrections.
      </p>
      <p>
        Orders to US ZIP Codes is shipped by US Postal Service Priority Mail which typically offers
        2-3 day delivery in most cases and good service at reasonable cost. Large orders to US ZIP
        Codes is shipped by UPS.
      </p>
      <p>
        When your order is processed you will receive a sales receipt by return email that contains
        the tracking number of your order so you can track your order until delivered. Be aware this
        email may get dumped into your junk folder.
      </p>
      <h4>Duplicate Orders</h4>
      <p>
        Sometimes you may get an error message submitting an order using our shopping cart when the
        internet is slow to process your order and return a notice of order confirmation. If you
        place the order a second time we will receive both orders. Don't be concerned! We know what
        happened and we will only process your original order.
      </p>
      <p>Any duplicate orders are promptly deleted.</p>
      <h4>International Shipping</h4>
      <p>
        The best option for international shipping is by Postal Service since it is the least
        expensive. We assume since you placed an international order with us you accept
        international postal charges and the cost varies between countries. We always try to keep
        shipping costs as low as possible.
      </p>
      <p>
        Any international shipping by UPS or FedEx is available but must be specifically requested
        and you must approve shipping charges by email before we process your order.
      </p>
      <p>
        If you have ordered a H-111A, Starter Kit and live in a country other than the USA we must
        know two things before we can process your order:
      </p>
      <ol>
        <li>What line (mains) voltage (110VAC or 220VAC) is available in your country?</li>
        <li>What type of wall plug is used (USA, UK or Euro)?</li>
      </ol>
      <p>
        This info is necessary to match the Power Supply in H-111A to your country's electrical grid
        system. There is an additional $25.00 USD charge for an international power supply which
        will be added to your order. Email us with any questions.
      </p>
      <h4>About International Payment by Credit Card</h4>
      <p>
        We cannot accept credit cards as payment for international orders. Foreign credit card bank
        company rules make this necessary. In these cases we require payment by Western Union or a
        bank transfer of funds before your order is shipped. If using a bank transfer we will
        provide information on where to transfer the funds.
      </p>
      <p>When funds are received we ship your order.</p>
      <p className="boost">
        IF WE CONTACT YOU ABOUT SHIPPING CHARGES WE WILL NOT CHARGE YOUR CREDIT CARD AND SHIP YOUR
        ORDER UNTIL YOU APPROVE THE SHIPPING CHARGES. PLEASE ANSWER OUR EMAIL AS SOON AS POSSIBLE.
      </p>
      <p>
        When your international order is shipped by US Postal Service be aware once your package
        leaves control of the USA Postal Service we no longer have the ability to track your order.
        It becomes the responsibility of the home country's postal service to deliver your order. It
        is out of our control. Please allow the necessary time for your order to be delivered to you
        which is determined by the time frame of your country's postal service.
      </p>
      <p>Thank you for your understanding!</p>
      <p>Please let us know BY EMAIL if you have any questions.</p>
      <p>
        Email is the best way to contact us about international orders. Many long distance land-line
        connections are difficult to understand. Email us is best. Thank you again for your order!
      </p>
      <hr />
      <Row>
        <Col width="32%">
          <PageCard
            icon="technicians"
            title="For Technicians"
            description="Learn about our technician training programs in electrical systems troubleshooting and electronics repair."
            url="/technicians"
          />
        </Col>
        <Col width="32%">
          <PageCard
            icon="shop-owners"
            title="For Shop Owners"
            description="Our training programs can train your technicians to be more proficient in electrical repair - saving labor hours, down time and fewer good parts replaced."
            url="/shop-owners"
          />
        </Col>
        <Col width="32%">
          <PageCard
            icon="teachers"
            title="For Teachers"
            description='We have the electrical-electronics curriculum that your students will need when they enter the job market. Be "THAT" teacher that showed them how!'
            url="/teachers"
          />
        </Col>
      </Row>
    </div>
  </Layout>
)

export default ThankYouPage
